import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';

export default class extends Controller {
  async toggle() {
    const response = await put(this.element.dataset.url, {
      responseKind: 'turbo_stream'
    });

    if (response.ok) {
      window.location.reload();
    }
  }
}
